import React, { useState, useEffect, useContext } from "react"
import Contents from "../layouts/components/Contents"
import {
  Seo,
  Expandables,
  ScrollDown,
  Section,
  Footer,
  Resellers,
  ThickArrow,
  Levels,
  PercentageBubles,
  Planet,
  LevelsWithInfo,
} from "../components"
//import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Controller,
  EffectFade,
  Mousewheel,
  Pagination,
  Swiper,
} from "swiper" //Pagination
import ThemeContext from "../stores/ThemeContext"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { useIntl } from "gatsby-plugin-intl" // , Link, FormattedMessage

SwiperCore.use([Controller, EffectFade, Mousewheel, Pagination])

const IndexPage = props => {
  const intl = useIntl()
  const isClient = typeof window === "object"
  let { dispatch, themeOpt } = useContext(ThemeContext)
  const { width } = useWindowDimensions()

  function isSafariBrowser() {
    var is_chrome = isClient && navigator.userAgent.indexOf("Chrome") > -1
    var is_safari = isClient && navigator.userAgent.indexOf("Safari") > -1

    if (is_safari) {
      if (is_chrome)
        // Chrome seems to have both Chrome and Safari userAgents
        return false
      else return true
    }
    return false
  }

  const [swiper, setSwiper] = useState(null)
  const [firstSectionSwiper, setFirstSectionSwiper] = useState(null)

  const showResellers = event => {
    if (event.type === "click" || event.key === "Enter" || event.key === " ") {
      dispatch({
        type: "SHOW_OVERLAY",
        payload: { overlayContent: <Resellers /> },
      })
    }
  }

  useEffect(() => {
    if (isClient && width && !swiper) {
      const sw = new Swiper(".swiper", {
        speed: 400,
        spaceBetween: 100,
        effect: "fade",
        direction: "vertical",
        spaceBetween: 0,
        slidesPerView: 1,
        init: false,
        pagination: {
          el: ".pagination",
          clickable: true,
          dynamicBullets: true,
        },
        mousewheel: {
          thresholdTime: isSafariBrowser() ? 1500 : 1500,
        },
      })
      sw.on("activeIndexChange", function (swiperObj) {
        console.log("slide changed")
        const { activeIndex } = swiperObj
        //console.log(activeIndex)
        dispatch({
          type: "SET_ACTIVE_SECTION",
          payload: activeIndex + 1,
        })
      })
      setSwiper(sw)
    }
  }, [isClient, width])

  useEffect(() => {
    if (swiper) {
      if (width >= 1200) {
        swiper.init()
      }
    }
  }, [swiper, width])

  return (
    <Contents containerClass="green homepage" isHomepage={true}>
      <Seo title={intl.formatMessage({ id: "main-title" })} />

      <h1 className="nod">{intl.formatMessage({ id: "main-title" })}</h1>

      <div id="sections">
        <div className="swiper main-swiper swiper-v">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <Section
                num={1}
                swiper={swiper}
                title={intl.formatMessage({ id: "s1-title" })}
                video={"black.mp4"}
                textDelay={3300}
                mobileScrolldown={true}
                videoText={intl.formatMessage({ id: "s1-video-text" })}
                illustration={
                  <ThickArrow
                    text={intl.formatMessage({ id: "s1-arrow-text" })}
                  />
                }
                illustrationMobile={<Levels intl={intl} />}
                onSwiperReady={setFirstSectionSwiper}
              >
                <div className="headline">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "s1-headline" }),
                    }}
                  ></h3>
                </div>
                <Expandables
                  contents={[
                    {
                      title: intl.formatMessage({ id: "s1-h-1" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s1-t-1" })}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: intl.formatMessage({ id: "s1-h-2" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s1-t-2" })}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: intl.formatMessage({ id: "s1-h-3" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s1-t-3" })}</p>
                          <LevelsWithInfo intl={intl} />
                        </React.Fragment>
                      ),
                    },
                  ]}
                />
              </Section>
            </div>

            <div className="swiper-slide">
              <Section
                num={2}
                swiper={swiper}
                title={intl.formatMessage({ id: "s2-title" })}
                video={"rose.mp4"}
                textDelay={3300}
                videoText={intl.formatMessage({ id: "s2-video-text" })}
                illustration={<PercentageBubles />}
              >
                <div className="headline">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "s2-headline" }),
                    }}
                  ></h3>
                </div>
                <Expandables
                  contents={[
                    {
                      title: intl.formatMessage({ id: "s2-h-1" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s2-t-1-1" })}</p>
                          <p>{intl.formatMessage({ id: "s2-t-1-2" })}</p>
                          <p>{intl.formatMessage({ id: "s2-t-1-3" })}</p>
                          <p>{intl.formatMessage({ id: "s2-t-1-4" })}</p>
                          <p>{intl.formatMessage({ id: "s2-t-1-5" })}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: intl.formatMessage({ id: "s2-h-2" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s2-t-2" })}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: intl.formatMessage({ id: "s2-h-3" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s2-t-3" })}</p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                />
              </Section>
            </div>

            <div className="swiper-slide">
              <Section
                num={3}
                swiper={swiper}
                title={intl.formatMessage({ id: "s3-title" })}
                video={"white.mp4"}
                textDelay={3300}
                imageOverlay={true}
                videoText={intl.formatMessage({ id: "s3-video-text" })}
                illustration={<Planet />}
              >
                <div className="headline">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "s3-headline" }),
                    }}
                  ></h3>
                </div>
                <Expandables
                  contents={[
                    {
                      title: intl.formatMessage({ id: "s3-h-1" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s3-t-1-1" })}</p>
                          <p>{intl.formatMessage({ id: "s3-t-1-2" })}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: intl.formatMessage({ id: "s3-h-2" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s3-t-2-1" })}</p>
                          <p>{intl.formatMessage({ id: "s3-t-2-2" })}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: intl.formatMessage({ id: "s3-h-3" }),
                      text: (
                        <React.Fragment>
                          <p>{intl.formatMessage({ id: "s3-t-3" })}</p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                />
              </Section>
            </div>

            <div className="swiper-slide">
              <Section
                num={4}
                swiper={swiper}
                title={intl.formatMessage({ id: "s4-title" })}
                video={"gold-cor.mp4"}
                textDelay={3300}
                videoText={intl.formatMessage({ id: "s4-video-text" })}
                illustration={false}
                videoButton={
                  <button
                    className="btn"
                    onClick={event => showResellers(event)}
                    onKeyDown={event => showResellers(event)}
                  >
                    {intl.formatMessage({ id: "s4-btn" })}
                  </button>
                }
              >
                <div className="headline">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: "s4-headline" }),
                    }}
                  ></h3>
                </div>
                <div
                  className="logos"
                  onClick={event => showResellers(event)}
                  onKeyDown={event => showResellers(event)}
                  role="button"
                  tabIndex="0"
                >
                  <figure>
                    <img src="/img/logo_O2_BW.svg" alt="O2" />
                  </figure>
                  <figure>
                    <img src="/img/logo_O2_BW.svg" alt="Orange" />
                  </figure>
                  <figure>
                    <img src="/img/logo_O2_BW.svg" alt="Mall" />
                  </figure>
                  <figure>
                    <img src="/img/logo_O2_BW.svg" alt="Vodafone" />
                  </figure>
                </div>
              </Section>
              <Footer />
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>

        <div className={`pagination-wrap page-${themeOpt.activeSection}`}>
          <div className="pagination"></div>
        </div>
      </div>

      <ScrollDown
        visible={themeOpt.activeSection === 1}
        swiperObjMain={swiper}
      />
    </Contents>
  )
}
export default IndexPage
